import Search from '@mui/icons-material/Search';
import styled from 'styled-components';
import { Radio } from '@mui/material';
import { GrayscaleColors } from '../../../utils/spcolors';
export const SearchIcon = styled(Search) `
  path {
    fill: ${GrayscaleColors.GrayDark01};
  }

  width: 24px;
  height: 24px;
`;
export const DropdownBody = styled.div `
  padding: 0;
  width: 790px;
  height: 400px;
  display: flex;
  justify-content: space-between;
`;
export const RecentlyUsedContainer = styled.div `
  margin-top: -12px;
  padding: 32px 24px;
  width: 248px;
  border-right: 1px solid #f3f3f4;
`;
export const IndustryRadio = styled(Radio) `
  && {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    svg {
      font-size: 19.21px;
    }
  }
`;
export const GroupOption = styled((props) => <div {...props}/>) `
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  pointer-events: ${({ noCoverageForIndustry }) => (noCoverageForIndustry ? 'none' : 'unset')};
  svg {
    color: ${({ isOnlyMainCategorySelected }) => isOnlyMainCategorySelected ? '#A4A5AB' : '#2c8dff'};
  }
  label {
    margin-left: 0;
    width: 100%;
    & > .MuiTypography-root {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      span {
        display: inline-flex;
        align-items: center;
      }
    }
  }
`;
