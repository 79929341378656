import Head from 'next/head';
import dynamic from 'next/dynamic';
import { code2meta } from '@/commons/code2meta';
import { Autocomplete, ClickAwayListener, Typography, ButtonBase, TextField, IconButton, Grid, } from '@mui/material';
import { CloseRounded } from '@/components/svgs';
import React, { useState } from 'react';
import { SearchIcon } from '@/components/layouts/appBarCategories/index.style';
import styled, { css } from 'styled-components';
import { useChartsSlicerV3 } from '@/utils/gql-swr';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { GripsButton } from '@/components/buttons/GripsButton';
import axios from 'axios';
const Layout = dynamic(() => import('@/components/layouts/ekpsLayout'), { ssr: false });
const breadcrumbs = [{ title: 'Discover', url: '/ekps/discover' }];
const Background = styled.div `
  background-color: ${(props) => props.theme.palette.gray.White};
  position: relative;
  justify-content: center;
  display: flex;
  & > svg {
    position: absolute;
  }
`;
const Container = styled.div `
  z-index: 0;
  margin: 0 auto;

  max-width: 722px;
  width: 100%;
  @media (min-width: 1638px) {
    padding-top: 248px;
  }

  & > * {
    z-index: 2;
    position: relative;
    font-family: ${(props) => props.theme.inter.fontFamily};
  }

  & > .MuiTypography-sh3,
  & > .MuiTypography-sh5 {
    text-align: center;
    width: 100%;
    display: block;
    font-family: ${(props) => props.theme.inter.fontFamily};
  }

  & > .MuiTypography-sh5 {
    padding: 0 65px;
  }
`;
const Option = styled.div `
  padding: 6px 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :not(:hover) {
    & > svg {
      opacity: 0;
    }
  }
`;
const OptionFirstColumn = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 36px;
`;
const TabButton = styled((props) => {
    return (<ButtonBase {...props} onClick={() => props.onSelect(props.value)}>
      {props.label}
    </ButtonBase>);
}) `
  && {
    padding: 7.5px 12px;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.palette.gray.GrayLight02};
    background-color: ${(props) => props.theme.palette.gray.GrayLight03};
    color: ${(props) => props.theme.palette.gray.GrayDark03};
    ${(props) => props.theme.inter.sh7};
    &:hover {
      ${({ isSelected }) => !isSelected &&
    css `
          color: ${(props) => props.theme.palette.gray.GrayDark03};
          border-color: ${(props) => props.theme.palette.primary.PurpleLight02};
          background: ${(props) => props.theme.palette.primary.PurpleLight03}};
        `}
    }

    ${(props) => props.isSelected
    ? css `
            color: ${(props) => props.theme.palette.primary.PurpleDark02};
            border-color: ${(props) => props.theme.palette.primary.PurpleDark02};
            font-weight: 700;
          `
    : ''}
  }
`;
const SearchInput = styled(TextField) `
  && {
    .MuiOutlinedInput-root {
      border-radius: 6px;
      padding: 4px 12px;
      input {
        ${(props) => props.theme.inter.sh6};
      }
      fieldset {
        border: 1px solid ${({ theme: { palette } }) => palette.gray.GrayLight01};
      }

      &:hover,
      &.Mui-focused {
        fieldset {
          border: 1px solid ${({ theme: { palette } }) => palette.gray.GrayDark02};
        }
      }
    }
  }
`;
const SearchOption = styled.li `
  && {
    flex-wrap: wrap;
    padding: 0;
    ${(props) => props.theme.inter.sh6};
  }
`;
const SearchContainer = styled.div `
  display: flex;
  width: 100%;
  .MuiAutocomplete-inputRoot {
    height: 49px;
  }
`;
const SearchDescription = styled(Typography) `
  color: ${(props) => props.color || props.theme.palette.gray.GrayDark01};
  ${(props) => props.theme.inter.b7};
  padding-left: 8px;
  padding-top: 4px;
`;
const Description = styled.div `
  margin-top: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const StyledClearIcon = styled((props) => (<IconButton {...props} disableRipple>
    <CloseRounded />
  </IconButton>)) `
  cursor: pointer;
  padding: 0;
`;
const SelectedProduct = styled.div `
  border: 2px solid ${(props) => props.theme.palette.primary.PurpleLight02};
  border-radius: 6px;
  padding: 12px 20px;
  margin-top: 10px;
  position: relative;
`;
const RemoveProduct = styled.div `
  position: absolute;
  right: 20px;
  top: 13px;
  cursor: pointer;

  &.green {
    color: ${(props) => props.theme.palette.primary.MintBase};
  }
  &.box {
    border: 1px solid ${(props) => props.theme.palette.gray.GrayLight01};
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 22px;
    border-radius: 6px;
    margin-top: -3px;
    background-color: ${(props) => props.theme.palette.gray.GrayLight02};
  }
`;
const StyledSelector = styled(Select) `
  width: 100%;
  height: 49px;
`;
const StyledGripsButton = styled.div `
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  button {
    width: 220px;
  }
  .MuiTypography-sh7 {
    font-size: 16px;
    font-weight: 500;
  }
`;
const Header = styled.h1 `
  ${(props) => props.theme.ginto.h1};
  font-family: ${(props) => props.theme.ginto.fontFamily};
  font-size: 64px;
  text-align: center;
  margin: 0 0 24px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.primary.PurpleDark03};
`;
const SubHeader = styled.p `
  ${(props) => props.theme.inter.b3};
  font-family: ${(props) => props.theme.inter.fontFamily};
  text-align: center;
  color: ${(props) => props.theme.palette.neutral.NeutralDark01};
  margin: 0 0 96px;
`;
const Step = styled.h4 `
  margin: 140px 0 24px;
  color: ${(props) => props.theme.palette.gray.GrayDark01};
  ${(props) => props.theme.ginto.h4};
  font-family: ${(props) => props.theme.ginto.fontFamily};
  font-weight: 400;
  text-align: center;
`;
const StyledTextField = styled(TextField) `
  width: 100%;
  .MuiOutlinedInput-root {
    height: 49px;
  }
  input {
    padding: 16.5px 14px;
  }
`;
const StepSignal = styled.li `
  display: inline-block;
  width: 170px;
  height: 6px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.palette.primary.PurpleBase};
  margin-left: 8px;
`;
const StepSignalContainer = styled.ul `
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
  &.step0 li + li {
    background-color: ${(props) => props.theme.palette.gray.GrayLight02};
  }
  &.step2 li {
    background-color: ${(props) => props.theme.palette.primary.MintBase};
  }
`;
const Home = () => {
    const [search, setSearch] = useState('');
    const [wizard, setWizard] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [country, setCountry] = useState('US');
    const countries = [code2meta.US, code2meta.GB, code2meta.DE];
    const selector = (<StyledSelector value={country || 'US'} onChange={(event) => {
            setCountry(event.target.value);
        }} inputProps={{ 'aria-label': 'Without label' }}>
      {countries.map((c, i) => (<MenuItem value={c.code} key={i}>
          {c.emoji} {c.name}
        </MenuItem>))}
    </StyledSelector>);
    const [isOpen, setIsOpen] = useState(false);
    const { data, isLoading } = useChartsSlicerV3(`input ILikeFilter {
      ilike: String!
    }
    
    input CountryFilters {
      COUNTRY: String!
    }
    
    input DomainFilters @inherits(name: CountryFilters) {
      DOMAIN: ILikeFilter!
    }
    
    input BrandFilers @inherits(name: CountryFilters) {
      BRAND_NAME: ILikeFilter!
    }
    
    input KeywordFilters @inherits(name: CountryFilters) {
      KEYWORD: ILikeFilter!
    }
    
    input CategoryNameFilters {
      NAME: ILikeFilter!
    }
    
    input CategoryFullNameFilters {
      FULL_NAME: ILikeFilter!
    }
    
    input CategoryFiltersTuple
      @tuple(definitions: [CategoryNameFilters, CategoryFullNameFilters], isRequired: true)
    
    input CategoryFilters {
      or: CategoryFiltersTuple
    }
    
    union Filters = DomainFilters | BrandFilers | KeywordFilters | CategoryFilters
    
    enum Field {
      DOMAIN
      BRAND
      CATEGORY
      GTIN
      ID
      KEYWORD
      TITLE
      BRAND_NAME
    }
    
    input FieldObj {
      name: Field!
      as: String
      from: String
    }
    
    union FieldType = Field | FieldObj
    
    query SearchQuery(
      $table: String!
      $field: [FieldType!]!
      $filters: Filters!
      $limit: Number
      $offset: Number
    ) {
      fetch(table: $table, filters: $filters) {
        result: combine(
          fields: $field
          type: Array
          sort_desc: search_volume_cleaned
          limit: $limit
          offset: $offset
        ) {
          gtin: GTIN @compare(value: $field, contains: "GTIN") @include
          id: ID @compare(value: $field, contains: "ID") @include
          title: TITLE @compare(value: $field, contains: ["GTIN", "ID"]) @include
          brand: BRAND @compare(value: $field, contains: "BRAND") @include
          brand_name: BRAND_NAME @compare(value: $field, contains: "BRAND") @include
          domain: DOMAIN @compare(value: $field, contains: "DOMAIN") @include
          category: CATEGORY @compare(value: $field, contains: "CATEGORY") @include
          keyword: KEYWORD @compare(value: $field, contains: "KEYWORD") @include
          search_volume_cleaned: sum(a: SEARCH_VOLUME_CLEANED)
        }
      }
    }
    
  `, 'ekps-breakdown-data', 'snowflake', {
        filters: {
            TITLE: {
                ilike: `%${search}%`,
            },
            COUNTRY: country,
        },
        table: 'EKPS_PRODUCTS_DETAILED',
        field: ['ID', 'TITLE'],
        limit: 10,
    });
    let options = search.length > 3 ? data?.data?.result || [] : [];
    const selectedIds = selectedProducts.map((p) => p && p.id);
    options = options.filter((o) => !selectedIds.includes(o.id));
    const isEmailEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);
    let headerText = 'Add a product';
    let stepText = 'STEP 1';
    let subHeaderText = 'Enter up to 6 product names that you would like to analyze.';
    if (wizard === 1) {
        headerText = 'Enter your details';
        stepText = 'STEP 2';
        subHeaderText = 'Where would you like us to send the results?';
    }
    if (wizard === 2) {
        headerText = 'Thank you!';
        stepText = '';
        subHeaderText =
            'Thank you for submitting your request. Your insights will be sent to the specified email address shortly.';
    }
    return (<div>
      <Head>
        <title>Create Next App</title>
        <meta name="description" content="Generated by create next app"/>
        <link rel="icon" href="/favicon.ico"/>
      </Head>
      <Container>
        <StepSignalContainer className={`step${wizard}`}>
          <StepSignal></StepSignal>
          <StepSignal></StepSignal>
        </StepSignalContainer>
        <Step>{stepText}</Step>
        <Header>{headerText}</Header>
        <SubHeader>{subHeaderText}</SubHeader>
        {wizard === 0 && (<div>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <SearchContainer>
                  <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                    <Autocomplete disabled={selectedProducts.length > 6} fullWidth inputValue={search ? search : ''} onFocus={() => setIsOpen(true)} options={options} filterOptions={(options, params) => {
                if (params.inputValue.length < 4) {
                    return [{ TITLE: 'Enter at least 4 caracters', ignore: true }];
                }
                else if (!isLoading && options.length === 0 && params.inputValue !== '') {
                    return [
                        {
                            TITLE: `There are no results for “${params.inputValue}”. Try a different phrase.`,
                            ignore: true,
                        },
                    ];
                }
                else if (isLoading) {
                    return [
                        {
                            TITLE: `Searching...`,
                            ignore: true,
                        },
                    ];
                }
                else {
                    return options;
                }
            }} freeSolo getOptionDisabled={(option) => typeof option === 'string'} onChange={(_, newValue) => {
                if (typeof newValue == 'string') {
                    if (!newValue)
                        return;
                    newValue = { TITLE: newValue };
                }
                if (newValue.ignore)
                    return;
                setSelectedProducts([...selectedProducts, newValue]);
            }} onInputChange={(e, inputValue) => {
                setSearch(inputValue);
            }} getOptionLabel={(option) => {
                return option['keyword']
                    ? option['keyword']
                    : typeof option === 'string'
                        ? option
                        : '';
            }} renderOption={(props, option) => {
                return (<SearchOption {...props} key={props.id}>
                            <Option>
                              <OptionFirstColumn>{option.TITLE}</OptionFirstColumn>
                            </Option>
                          </SearchOption>);
            }} renderInput={(params) => (<SearchInput {...params} InputProps={{
                    ...params.InputProps,
                    startAdornment: <SearchIcon />,
                    endAdornment: <StyledClearIcon onClick={() => setSearch('')}/>,
                }}/>)}/>
                  </ClickAwayListener>
                </SearchContainer>
              </Grid>
              <Grid item md={4}>
                {selector}
              </Grid>
            </Grid>
            {selectedProducts.map((p, i) => (<SelectedProduct key={i}>
                {p?.TITLE}
                <RemoveProduct className="box" onClick={() => {
                    selectedProducts.splice(i, 1);
                    setSelectedProducts([...selectedProducts]);
                    return;
                }}>
                  ×
                </RemoveProduct>
              </SelectedProduct>))}
            <StyledGripsButton>
              <GripsButton disabled={selectedProducts.length == 0} title={'Continue'} size={'md'} onClick={() => {
                window.dataLayer.push({
                    event: 'search',
                    query: selectedProducts.map((r) => r.TITLE).join('|'),
                });
                setWizard(1);
            }}/>
            </StyledGripsButton>
          </div>)}

        {wizard === 1 && (<div>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <StyledTextField variant="outlined" label="Full name" value={name} onChange={(event) => setName(event.target.value)}/>
              </Grid>
              <Grid item md={6}>
                <StyledTextField variant="outlined" label="Enter your email address here" value={email} error={email.length < 3 ? false : !isEmailEmail} helperText={email.length < 3 || isEmailEmail ? '' : 'Should be email'} onChange={(event) => setEmail(event.target.value)}/>
              </Grid>
            </Grid>
            <StyledGripsButton>
              <GripsButton title={'Send request'} disabled={!isEmailEmail || name.length < 2} size={'md'} onClick={() => {
                axios.post('/api/send', {
                    text: `request from '${name}' '${email}', for country: ${country} \n${selectedProducts
                        .map((p) => `-${p.TITLE} (${p.id})\n`)
                        .join('')}\n\n\n\n`,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                window.dataLayer.push({ event: 'conversion', email: email });
                setWizard(2);
            }}/>
            </StyledGripsButton>
          </div>)}

        {wizard === 2 && (<div>
            {selectedProducts.map((p, i) => (<SelectedProduct key={i}>
                {p?.TITLE}
                <RemoveProduct className="green">Request submitted</RemoveProduct>
              </SelectedProduct>))}
            <StyledGripsButton>
              <GripsButton title={'Submit another request'} size={'md'} onClick={() => {
                setWizard(0);
                setSelectedProducts([]);
            }}/>
            </StyledGripsButton>
          </div>)}
      </Container>
    </div>);
};
export default Home;
