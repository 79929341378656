import styled from 'styled-components';
import { Typography } from '@mui/material';
import { GrayscaleColors } from '../../utils/spcolors';
const PrimaryButton = styled.button `
  cursor: pointer;
  height: ${({ size }) => {
    switch (size) {
        case 'md':
            return '40px';
        case 'sm':
            return '32px';
        default:
            return '48px';
    }
}};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: ${({ disabled }) => disabled ? '#D1D2D5' : '#8226f3'};
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'unset'};
  box-shadow: ${({ noShadow }) => noShadow ? 'unset' : '0px 5px 10px rgba(0, 0, 0, 0.08)'};
  border: none;
  border-radius: 6px;

  &:hover {
    background: #a95dff;
  }

  &:focus {
    background: #6307c9;
  }
`;
const OutlinedButton = styled(PrimaryButton) `
  background: white;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
  border: 1px solid #8226f3;
  &:hover {
    background: #f9f3ff;
  }

  &:focus {
    background: #f1e2ff;
  }
`;
const SecondaryButton = styled(OutlinedButton) `
  background: #fbfbfb;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
  border: 1px solid #f3f3f4;
  &:hover {
    background: #fbfbfb;
  }

  &:focus {
    background: #fbfbfb;
  }
`;
export const GripsButton = (props) => {
    const buttonProps = {
        type: props.type || 'button',
        onClick: props.onClick,
        size: props.size || 'lg',
        disabled: props.disabled,
        noShadow: props.noShadow || false,
    };
    if (props.variant === 'outlined') {
        return (<OutlinedButton {...buttonProps}>
        <Typography color={'#8226F3'} component={'div'} noWrap variant={buttonProps.size === 'sm' ? 'sh8' : 'h5'} fontWeight={700}>
          {props.title}
        </Typography>
      </OutlinedButton>);
    }
    if (props.variant === 'secondary') {
        return (<SecondaryButton {...buttonProps}>
        <Typography color={'#1B1D2E'} component={'div'} noWrap variant={buttonProps.size === 'sm' ? 'sh8' : 'h5'} fontWeight={700}>
          {props.title}
        </Typography>
      </SecondaryButton>);
    }
    return (<PrimaryButton {...buttonProps}>
      <Typography color={GrayscaleColors.White} noWrap component={'div'} variant={buttonProps.size === 'sm' ? 'sh8' : 'h5'} fontWeight={700}>
        {props.title}
      </Typography>
    </PrimaryButton>);
};
