export const code2meta = {
    AC: {
        code: 'AC',
        unicode: 'U+1F1E6 U+1F1E8',
        name: 'Ascension Island',
        region: 'EMEA',
        emoji: '🇦🇨',
    },
    AD: {
        code: 'AD',
        unicode: 'U+1F1E6 U+1F1E9',
        name: 'Andorra',
        emoji: '🇦🇩',
        region: 'EMEA',
    },
    AE: {
        code: 'AE',
        unicode: 'U+1F1E6 U+1F1EA',
        name: 'United Arab Emirates',
        article: true,
        emoji: '🇦🇪',
        region: 'EMEA',
    },
    AF: {
        code: 'AF',
        unicode: 'U+1F1E6 U+1F1EB',
        name: 'Afghanistan',
        emoji: '🇦🇫',
        region: 'APAC',
    },
    AG: {
        code: 'AG',
        unicode: 'U+1F1E6 U+1F1EC',
        name: 'Antigua & Barbuda',
        emoji: '🇦🇬',
        region: 'LATM',
    },
    AI: {
        code: 'AI',
        unicode: 'U+1F1E6 U+1F1EE',
        name: 'Anguilla',
        emoji: '🇦🇮',
        region: 'LATM',
    },
    AL: {
        code: 'AL',
        unicode: 'U+1F1E6 U+1F1F1',
        name: 'Albania',
        emoji: '🇦🇱',
        region: 'EMEA',
    },
    AM: {
        code: 'AM',
        unicode: 'U+1F1E6 U+1F1F2',
        name: 'Armenia',
        emoji: '🇦🇲',
        region: 'EMEA',
    },
    AO: {
        code: 'AO',
        unicode: 'U+1F1E6 U+1F1F4',
        name: 'Angola',
        emoji: '🇦🇴',
        region: 'EMEA',
    },
    AQ: {
        code: 'AQ',
        unicode: 'U+1F1E6 U+1F1F6',
        name: 'Antarctica',
        region: 'EMEA',
        emoji: '🇦🇶',
    },
    AR: {
        code: 'AR',
        unicode: 'U+1F1E6 U+1F1F7',
        name: 'Argentina',
        emoji: '🇦🇷',
        region: 'LATM',
    },
    AS: {
        code: 'AS',
        unicode: 'U+1F1E6 U+1F1F8',
        name: 'American Samoa',
        region: 'APAC',
        emoji: '🇦🇸',
    },
    AT: {
        code: 'AT',
        unicode: 'U+1F1E6 U+1F1F9',
        name: 'Austria',
        emoji: '🇦🇹',
        region: 'EMEA',
    },
    AU: {
        code: 'AU',
        unicode: 'U+1F1E6 U+1F1FA',
        name: 'Australia',
        emoji: '🇦🇺',
        region: 'APAC',
    },
    AW: {
        code: 'AW',
        unicode: 'U+1F1E6 U+1F1FC',
        name: 'Aruba',
        emoji: '🇦🇼',
        region: 'LATM',
    },
    AX: {
        code: 'AX',
        unicode: 'U+1F1E6 U+1F1FD',
        name: 'Åland Islands',
        region: 'EMEA',
        emoji: '🇦🇽',
    },
    AZ: {
        code: 'AZ',
        unicode: 'U+1F1E6 U+1F1FF',
        name: 'Azerbaijan',
        emoji: '🇦🇿',
        region: 'APAC',
    },
    BA: {
        code: 'BA',
        unicode: 'U+1F1E7 U+1F1E6',
        name: 'Bosnia & Herzegovina',
        emoji: '🇧🇦',
        region: 'EMEA',
    },
    BB: {
        code: 'BB',
        unicode: 'U+1F1E7 U+1F1E7',
        name: 'Barbados',
        emoji: '🇧🇧',
        region: 'LATM',
    },
    BD: {
        code: 'BD',
        unicode: 'U+1F1E7 U+1F1E9',
        name: 'Bangladesh',
        emoji: '🇧🇩',
        region: 'APAC',
    },
    BE: {
        code: 'BE',
        unicode: 'U+1F1E7 U+1F1EA',
        name: 'Belgium',
        emoji: '🇧🇪',
        region: 'EMEA',
    },
    BF: {
        code: 'BF',
        unicode: 'U+1F1E7 U+1F1EB',
        name: 'Burkina Faso',
        emoji: '🇧🇫',
        region: 'EMEA',
    },
    BG: {
        code: 'BG',
        unicode: 'U+1F1E7 U+1F1EC',
        name: 'Bulgaria',
        emoji: '🇧🇬',
        region: 'EMEA',
    },
    BH: {
        code: 'BH',
        unicode: 'U+1F1E7 U+1F1ED',
        name: 'Bahrain',
        emoji: '🇧🇭',
        region: 'EMEA',
    },
    BI: {
        code: 'BI',
        unicode: 'U+1F1E7 U+1F1EE',
        name: 'Burundi',
        emoji: '🇧🇮',
        region: 'EMEA',
    },
    BJ: {
        code: 'BJ',
        unicode: 'U+1F1E7 U+1F1EF',
        name: 'Benin',
        emoji: '🇧🇯',
        region: 'EMEA',
    },
    BL: {
        code: 'BL',
        unicode: 'U+1F1E7 U+1F1F1',
        name: 'St. Barthélemy',
        region: 'EMEA',
        emoji: '🇧🇱',
    },
    BM: {
        code: 'BM',
        unicode: 'U+1F1E7 U+1F1F2',
        name: 'Bermuda',
        region: 'LATM',
        emoji: '🇧🇲',
    },
    BN: {
        code: 'BN',
        unicode: 'U+1F1E7 U+1F1F3',
        name: 'Brunei',
        emoji: '🇧🇳',
        region: 'APAC',
    },
    BO: {
        code: 'BO',
        unicode: 'U+1F1E7 U+1F1F4',
        name: 'Bolivia',
        emoji: '🇧🇴',
        region: 'LATM',
    },
    BQ: {
        code: 'BQ',
        unicode: 'U+1F1E7 U+1F1F6',
        name: 'Caribbean Netherlands',
        region: 'EMEA',
        emoji: '🇧🇶',
    },
    BR: {
        code: 'BR',
        unicode: 'U+1F1E7 U+1F1F7',
        name: 'Brazil',
        emoji: '🇧🇷',
        region: 'LATM',
    },
    BS: {
        code: 'BS',
        unicode: 'U+1F1E7 U+1F1F8',
        name: 'Bahamas',
        article: true,
        emoji: '🇧🇸',
        region: 'LATM',
    },
    BT: {
        code: 'BT',
        unicode: 'U+1F1E7 U+1F1F9',
        name: 'Bhutan',
        emoji: '🇧🇹',
        region: 'APAC',
    },
    BV: {
        code: 'BV',
        unicode: 'U+1F1E7 U+1F1FB',
        name: 'Bouvet Island',
        region: 'EMEA',
        emoji: '🇧🇻',
    },
    BW: {
        code: 'BW',
        unicode: 'U+1F1E7 U+1F1FC',
        name: 'Botswana',
        emoji: '🇧🇼',
        region: 'EMEA',
    },
    BY: {
        code: 'BY',
        unicode: 'U+1F1E7 U+1F1FE',
        name: 'Belarus',
        emoji: '🇧🇾',
        region: 'EMEA',
    },
    BZ: {
        code: 'BZ',
        unicode: 'U+1F1E7 U+1F1FF',
        name: 'Belize',
        emoji: '🇧🇿',
        region: 'LATM',
    },
    CA: {
        code: 'CA',
        unicode: 'U+1F1E8 U+1F1E6',
        name: 'Canada',
        emoji: '🇨🇦',
        region: 'NorthAmerica',
    },
    CC: {
        code: 'CC',
        unicode: 'U+1F1E8 U+1F1E8',
        name: 'Cocos (Keeling) Islands',
        region: 'APAC',
        emoji: '🇨🇨',
    },
    CD: {
        code: 'CD',
        unicode: 'U+1F1E8 U+1F1E9',
        name: 'Congo - Kinshasa',
        region: 'EMEA',
        emoji: '🇨🇩',
    },
    CF: {
        code: 'CF',
        unicode: 'U+1F1E8 U+1F1EB',
        name: 'Central African Republic',
        article: true,
        emoji: '🇨🇫',
        region: 'EMEA',
    },
    CG: {
        code: 'CG',
        unicode: 'U+1F1E8 U+1F1EC',
        name: 'Congo - Brazzaville',
        emoji: '🇨🇬',
        region: 'EMEA',
    },
    CH: {
        code: 'CH',
        unicode: 'U+1F1E8 U+1F1ED',
        name: 'Switzerland',
        emoji: '🇨🇭',
        region: 'EMEA',
    },
    CI: {
        code: 'CI',
        unicode: 'U+1F1E8 U+1F1EE',
        name: 'Côte d’Ivoire',
        emoji: '🇨🇮',
        region: 'EMEA',
    },
    CK: {
        code: 'CK',
        unicode: 'U+1F1E8 U+1F1F0',
        name: 'Cook Islands',
        emoji: '🇨🇰',
        region: 'APAC',
    },
    CL: {
        code: 'CL',
        unicode: 'U+1F1E8 U+1F1F1',
        name: 'Chile',
        emoji: '🇨🇱',
        region: 'LATM',
    },
    CM: {
        code: 'CM',
        unicode: 'U+1F1E8 U+1F1F2',
        name: 'Cameroon',
        emoji: '🇨🇲',
        region: 'EMEA',
    },
    CN: {
        code: 'CN',
        unicode: 'U+1F1E8 U+1F1F3',
        name: 'China',
        emoji: '🇨🇳',
        region: 'APAC',
    },
    CO: {
        code: 'CO',
        unicode: 'U+1F1E8 U+1F1F4',
        name: 'Colombia',
        emoji: '🇨🇴',
        region: 'LATM',
    },
    CP: {
        code: 'CP',
        unicode: 'U+1F1E8 U+1F1F5',
        name: 'Clipperton Island',
        region: 'EMEA',
        emoji: '🇨🇵',
    },
    CR: {
        code: 'CR',
        unicode: 'U+1F1E8 U+1F1F7',
        name: 'Costa Rica',
        emoji: '🇨🇷',
        region: 'LATM',
    },
    CU: {
        code: 'CU',
        unicode: 'U+1F1E8 U+1F1FA',
        name: 'Cuba',
        emoji: '🇨🇺',
        region: 'LATM',
    },
    CV: {
        code: 'CV',
        unicode: 'U+1F1E8 U+1F1FB',
        name: 'Cape Verde',
        emoji: '🇨🇻',
        region: 'EMEA',
    },
    CW: {
        code: 'CW',
        unicode: 'U+1F1E8 U+1F1FC',
        name: 'Curaçao',
        region: 'EMEA',
        emoji: '🇨🇼',
    },
    CX: {
        code: 'CX',
        unicode: 'U+1F1E8 U+1F1FD',
        name: 'Christmas Island',
        region: 'APAC',
        emoji: '🇨🇽',
    },
    CY: {
        code: 'CY',
        unicode: 'U+1F1E8 U+1F1FE',
        name: 'Cyprus',
        emoji: '🇨🇾',
        region: 'EMEA',
    },
    CZ: {
        code: 'CZ',
        unicode: 'U+1F1E8 U+1F1FF',
        name: 'Czechia',
        article: true,
        emoji: '🇨🇿',
        region: 'EMEA',
    },
    DE: {
        code: 'DE',
        unicode: 'U+1F1E9 U+1F1EA',
        name: 'Germany',
        emoji: '🇩🇪',
        region: 'EMEA',
    },
    DG: {
        code: 'DG',
        unicode: 'U+1F1E9 U+1F1EC',
        name: 'Diego Garcia',
        region: 'APAC',
        emoji: '🇩🇬',
    },
    DJ: {
        code: 'DJ',
        unicode: 'U+1F1E9 U+1F1EF',
        name: 'Djibouti',
        emoji: '🇩🇯',
        region: 'EMEA',
    },
    DK: {
        code: 'DK',
        unicode: 'U+1F1E9 U+1F1F0',
        name: 'Denmark',
        emoji: '🇩🇰',
        region: 'EMEA',
    },
    DM: {
        code: 'DM',
        unicode: 'U+1F1E9 U+1F1F2',
        name: 'Dominica',
        emoji: '🇩🇲',
        region: 'LATM',
    },
    DO: {
        code: 'DO',
        unicode: 'U+1F1E9 U+1F1F4',
        name: 'Dominican Republic',
        article: true,
        emoji: '🇩🇴',
        region: 'LATM',
    },
    DZ: {
        code: 'DZ',
        unicode: 'U+1F1E9 U+1F1FF',
        name: 'Algeria',
        emoji: '🇩🇿',
        region: 'EMEA',
    },
    EA: {
        code: 'EA',
        unicode: 'U+1F1EA U+1F1E6',
        name: 'Ceuta & Melilla',
        region: 'EMEA',
        emoji: '🇪🇦',
    },
    EC: {
        code: 'EC',
        unicode: 'U+1F1EA U+1F1E8',
        name: 'Ecuador',
        emoji: '🇪🇨',
        region: 'LATM',
    },
    EE: {
        code: 'EE',
        unicode: 'U+1F1EA U+1F1EA',
        name: 'Estonia',
        emoji: '🇪🇪',
        region: 'EMEA',
    },
    EG: {
        code: 'EG',
        unicode: 'U+1F1EA U+1F1EC',
        name: 'Egypt',
        emoji: '🇪🇬',
        region: 'EMEA',
    },
    EH: {
        code: 'EH',
        unicode: 'U+1F1EA U+1F1ED',
        name: 'Western Sahara',
        emoji: '🇪🇭',
        region: 'EMEA',
    },
    ER: {
        code: 'ER',
        unicode: 'U+1F1EA U+1F1F7',
        name: 'Eritrea',
        emoji: '🇪🇷',
        region: 'EMEA',
    },
    ES: {
        code: 'ES',
        unicode: 'U+1F1EA U+1F1F8',
        name: 'Spain',
        emoji: '🇪🇸',
        region: 'EMEA',
    },
    ET: {
        code: 'ET',
        unicode: 'U+1F1EA U+1F1F9',
        name: 'Ethiopia',
        emoji: '🇪🇹',
        region: 'EMEA',
    },
    FI: {
        code: 'FI',
        unicode: 'U+1F1EB U+1F1EE',
        name: 'Finland',
        emoji: '🇫🇮',
        region: 'EMEA',
    },
    FJ: {
        code: 'FJ',
        unicode: 'U+1F1EB U+1F1EF',
        name: 'Fiji',
        emoji: '🇫🇯',
        region: 'APAC',
    },
    FK: {
        code: 'FK',
        unicode: 'U+1F1EB U+1F1F0',
        name: 'Falkland Islands',
        article: true,
        emoji: '🇫🇰',
        region: 'LATM',
    },
    FM: {
        code: 'FM',
        unicode: 'U+1F1EB U+1F1F2',
        name: 'Micronesia',
        emoji: '🇫🇲',
        region: 'APAC',
    },
    FO: {
        code: 'FO',
        unicode: 'U+1F1EB U+1F1F4',
        name: 'Faroe Islands',
        emoji: '🇫🇴',
        region: 'EMEA',
    },
    FR: {
        code: 'FR',
        unicode: 'U+1F1EB U+1F1F7',
        name: 'France',
        emoji: '🇫🇷',
        region: 'EMEA',
    },
    GA: {
        code: 'GA',
        unicode: 'U+1F1EC U+1F1E6',
        name: 'Gabon',
        emoji: '🇬🇦',
        region: 'EMEA',
    },
    GB: {
        code: 'GB',
        unicode: 'U+1F1EC U+1F1E7',
        name: 'United Kingdom',
        article: true,
        emoji: '🇬🇧',
        region: 'EMEA',
    },
    GD: {
        code: 'GD',
        unicode: 'U+1F1EC U+1F1E9',
        name: 'Grenada',
        emoji: '🇬🇩',
        region: 'LATM',
    },
    GE: {
        code: 'GE',
        unicode: 'U+1F1EC U+1F1EA',
        name: 'Georgia',
        emoji: '🇬🇪',
        region: 'EMEA',
    },
    GF: {
        code: 'GF',
        unicode: 'U+1F1EC U+1F1EB',
        name: 'French Guiana',
        emoji: '🇬🇫',
        region: 'LATM',
    },
    GG: {
        code: 'GG',
        unicode: 'U+1F1EC U+1F1EC',
        name: 'Guernsey',
        region: 'EMEA',
        emoji: '🇬🇬',
    },
    GH: {
        code: 'GH',
        unicode: 'U+1F1EC U+1F1ED',
        name: 'Ghana',
        emoji: '🇬🇭',
        region: 'EMEA',
    },
    GI: {
        code: 'GI',
        unicode: 'U+1F1EC U+1F1EE',
        name: 'Gibraltar',
        emoji: '🇬🇮',
        region: 'EMEA',
    },
    GL: {
        code: 'GL',
        unicode: 'U+1F1EC U+1F1F1',
        name: 'Greenland',
        region: 'EMEA',
        emoji: '🇬🇱',
    },
    GM: {
        code: 'GM',
        unicode: 'U+1F1EC U+1F1F2',
        name: 'Gambia',
        article: true,
        emoji: '🇬🇲',
        region: 'EMEA',
    },
    GN: {
        code: 'GN',
        unicode: 'U+1F1EC U+1F1F3',
        name: 'Guinea',
        emoji: '🇬🇳',
        region: 'EMEA',
    },
    GP: {
        code: 'GP',
        unicode: 'U+1F1EC U+1F1F5',
        name: 'Guadeloupe',
        emoji: '🇬🇵',
        region: 'LATM',
    },
    GQ: {
        code: 'GQ',
        unicode: 'U+1F1EC U+1F1F6',
        name: 'Equatorial Guinea',
        emoji: '🇬🇶',
        region: 'EMEA',
    },
    GR: {
        code: 'GR',
        unicode: 'U+1F1EC U+1F1F7',
        name: 'Greece',
        emoji: '🇬🇷',
        region: 'EMEA',
    },
    GS: {
        code: 'GS',
        unicode: 'U+1F1EC U+1F1F8',
        name: 'South Georgia & South Sandwich Islands',
        region: 'LATM',
        emoji: '🇬🇸',
    },
    GT: {
        code: 'GT',
        unicode: 'U+1F1EC U+1F1F9',
        name: 'Guatemala',
        emoji: '🇬🇹',
        region: 'LATM',
    },
    GU: {
        code: 'GU',
        unicode: 'U+1F1EC U+1F1FA',
        name: 'Guam',
        region: 'APAC',
        emoji: '🇬🇺',
    },
    GW: {
        code: 'GW',
        unicode: 'U+1F1EC U+1F1FC',
        name: 'Guinea-Bissau',
        emoji: '🇬🇼',
        region: 'EMEA',
    },
    GY: {
        code: 'GY',
        unicode: 'U+1F1EC U+1F1FE',
        name: 'Guyana',
        emoji: '🇬🇾',
        region: 'LATM',
    },
    HK: {
        code: 'HK',
        unicode: 'U+1F1ED U+1F1F0',
        name: 'Hong Kong SAR China',
        emoji: '🇭🇰',
        region: 'APAC',
    },
    HM: {
        code: 'HM',
        unicode: 'U+1F1ED U+1F1F2',
        name: 'Heard & McDonald Islands',
        region: 'EMEA',
        emoji: '🇭🇲',
    },
    HN: {
        code: 'HN',
        unicode: 'U+1F1ED U+1F1F3',
        name: 'Honduras',
        emoji: '🇭🇳',
        region: 'LATM',
    },
    HR: {
        code: 'HR',
        unicode: 'U+1F1ED U+1F1F7',
        name: 'Croatia',
        emoji: '🇭🇷',
        region: 'EMEA',
    },
    HT: {
        code: 'HT',
        unicode: 'U+1F1ED U+1F1F9',
        name: 'Haiti',
        emoji: '🇭🇹',
        region: 'LATM',
    },
    HU: {
        code: 'HU',
        unicode: 'U+1F1ED U+1F1FA',
        name: 'Hungary',
        emoji: '🇭🇺',
        region: 'EMEA',
    },
    IC: {
        code: 'IC',
        unicode: 'U+1F1EE U+1F1E8',
        name: 'Canary Islands',
        region: 'EMEA',
        emoji: '🇮🇨',
    },
    ID: {
        code: 'ID',
        unicode: 'U+1F1EE U+1F1E9',
        name: 'Indonesia',
        emoji: '🇮🇩',
        region: 'APAC',
    },
    IE: {
        code: 'IE',
        unicode: 'U+1F1EE U+1F1EA',
        name: 'Ireland',
        emoji: '🇮🇪',
        region: 'EMEA',
    },
    IL: {
        code: 'IL',
        unicode: 'U+1F1EE U+1F1F1',
        name: 'Israel',
        emoji: '🇮🇱',
        region: 'EMEA',
    },
    IM: {
        code: 'IM',
        unicode: 'U+1F1EE U+1F1F2',
        name: 'Isle of Man',
        region: 'EMEA',
        article: true,
        emoji: '🇮🇲',
    },
    IN: {
        code: 'IN',
        unicode: 'U+1F1EE U+1F1F3',
        name: 'India',
        emoji: '🇮🇳',
        region: 'APAC',
    },
    IO: {
        code: 'IO',
        unicode: 'U+1F1EE U+1F1F4',
        name: 'British Indian Ocean Territory',
        region: 'APAC',
        emoji: '🇮🇴',
    },
    IQ: {
        code: 'IQ',
        unicode: 'U+1F1EE U+1F1F6',
        name: 'Iraq',
        emoji: '🇮🇶',
        region: 'EMEA',
    },
    IR: {
        code: 'IR',
        unicode: 'U+1F1EE U+1F1F7',
        name: 'Iran',
        emoji: '🇮🇷',
        region: 'EMEA',
    },
    IS: {
        code: 'IS',
        unicode: 'U+1F1EE U+1F1F8',
        name: 'Iceland',
        emoji: '🇮🇸',
        region: 'EMEA',
    },
    IT: {
        code: 'IT',
        unicode: 'U+1F1EE U+1F1F9',
        name: 'Italy',
        emoji: '🇮🇹',
        region: 'EMEA',
    },
    JE: {
        code: 'JE',
        unicode: 'U+1F1EF U+1F1EA',
        name: 'Jersey',
        region: 'EMEA',
        emoji: '🇯🇪',
    },
    JM: {
        code: 'JM',
        unicode: 'U+1F1EF U+1F1F2',
        name: 'Jamaica',
        emoji: '🇯🇲',
        region: 'LATM',
    },
    JO: {
        code: 'JO',
        unicode: 'U+1F1EF U+1F1F4',
        name: 'Jordan',
        emoji: '🇯🇴',
        region: 'EMEA',
    },
    JP: {
        code: 'JP',
        unicode: 'U+1F1EF U+1F1F5',
        name: 'Japan',
        emoji: '🇯🇵',
        region: 'APAC',
    },
    KE: {
        code: 'KE',
        unicode: 'U+1F1F0 U+1F1EA',
        name: 'Kenya',
        emoji: '🇰🇪',
        region: 'EMEA',
    },
    KG: {
        code: 'KG',
        unicode: 'U+1F1F0 U+1F1EC',
        name: 'Kyrgyzstan',
        emoji: '🇰🇬',
        region: 'APAC',
    },
    KH: {
        code: 'KH',
        unicode: 'U+1F1F0 U+1F1ED',
        name: 'Cambodia',
        emoji: '🇰🇭',
        region: 'APAC',
    },
    KI: {
        code: 'KI',
        unicode: 'U+1F1F0 U+1F1EE',
        name: 'Kiribati',
        emoji: '🇰🇮',
        region: 'APAC',
    },
    KM: {
        code: 'KM',
        unicode: 'U+1F1F0 U+1F1F2',
        name: 'Comoros',
        article: true,
        emoji: '🇰🇲',
        region: 'EMEA',
    },
    KN: {
        code: 'KN',
        unicode: 'U+1F1F0 U+1F1F3',
        name: 'St. Kitts & Nevis',
        region: 'LATM',
        emoji: '🇰🇳',
    },
    KP: {
        code: 'KP',
        unicode: 'U+1F1F0 U+1F1F5',
        name: 'North Korea',
        region: 'APAC',
        emoji: '🇰🇵',
    },
    KR: {
        code: 'KR',
        unicode: 'U+1F1F0 U+1F1F7',
        name: 'South Korea',
        emoji: '🇰🇷',
        region: 'APAC',
    },
    KW: {
        code: 'KW',
        unicode: 'U+1F1F0 U+1F1FC',
        name: 'Kuwait',
        emoji: '🇰🇼',
        region: 'EMEA',
    },
    KY: {
        code: 'KY',
        unicode: 'U+1F1F0 U+1F1FE',
        name: 'Cayman Islands',
        article: true,
        emoji: '🇰🇾',
        region: 'LATM',
    },
    KZ: {
        code: 'KZ',
        unicode: 'U+1F1F0 U+1F1FF',
        name: 'Kazakhstan',
        emoji: '🇰🇿',
        region: 'APAC',
    },
    LA: {
        code: 'LA',
        unicode: 'U+1F1F1 U+1F1E6',
        name: 'Laos',
        emoji: '🇱🇦',
        region: 'APAC',
    },
    LB: {
        code: 'LB',
        unicode: 'U+1F1F1 U+1F1E7',
        name: 'Lebanon',
        emoji: '🇱🇧',
        region: 'EMEA',
    },
    LC: {
        code: 'LC',
        unicode: 'U+1F1F1 U+1F1E8',
        name: 'St. Lucia',
        region: 'LATM',
        emoji: '🇱🇨',
    },
    LI: {
        code: 'LI',
        unicode: 'U+1F1F1 U+1F1EE',
        name: 'Liechtenstein',
        region: 'EMEA',
        emoji: '🇱🇮',
    },
    LK: {
        code: 'LK',
        unicode: 'U+1F1F1 U+1F1F0',
        name: 'Sri Lanka',
        emoji: '🇱🇰',
        region: 'APAC',
    },
    LR: {
        code: 'LR',
        unicode: 'U+1F1F1 U+1F1F7',
        name: 'Liberia',
        emoji: '🇱🇷',
        region: 'EMEA',
    },
    LS: {
        code: 'LS',
        unicode: 'U+1F1F1 U+1F1F8',
        name: 'Lesotho',
        emoji: '🇱🇸',
        region: 'EMEA',
    },
    LT: {
        code: 'LT',
        unicode: 'U+1F1F1 U+1F1F9',
        name: 'Lithuania',
        emoji: '🇱🇹',
        region: 'EMEA',
    },
    LU: {
        code: 'LU',
        unicode: 'U+1F1F1 U+1F1FA',
        name: 'Luxembourg',
        emoji: '🇱🇺',
        region: 'EMEA',
    },
    LV: {
        code: 'LV',
        unicode: 'U+1F1F1 U+1F1FB',
        name: 'Latvia',
        emoji: '🇱🇻',
        region: 'EMEA',
    },
    LY: {
        code: 'LY',
        unicode: 'U+1F1F1 U+1F1FE',
        name: 'Libya',
        emoji: '🇱🇾',
        region: 'EMEA',
    },
    MA: {
        code: 'MA',
        unicode: 'U+1F1F2 U+1F1E6',
        name: 'Morocco',
        emoji: '🇲🇦',
        region: 'EMEA',
    },
    MC: {
        code: 'MC',
        unicode: 'U+1F1F2 U+1F1E8',
        name: 'Monaco',
        emoji: '🇲🇨',
        region: 'EMEA',
    },
    MD: {
        code: 'MD',
        unicode: 'U+1F1F2 U+1F1E9',
        name: 'Moldova',
        region: 'EMEA',
        emoji: '🇲🇩',
    },
    ME: {
        code: 'ME',
        unicode: 'U+1F1F2 U+1F1EA',
        name: 'Montenegro',
        region: 'EMEA',
        emoji: '🇲🇪',
    },
    MF: {
        code: 'MF',
        unicode: 'U+1F1F2 U+1F1EB',
        name: 'St. Martin',
        region: 'EMEA',
        emoji: '🇲🇫',
    },
    MG: {
        code: 'MG',
        unicode: 'U+1F1F2 U+1F1EC',
        name: 'Madagascar',
        emoji: '🇲🇬',
        region: 'EMEA',
    },
    MH: {
        code: 'MH',
        unicode: 'U+1F1F2 U+1F1ED',
        name: 'Marshall Islands',
        article: true,
        emoji: '🇲🇭',
        region: 'APAC',
    },
    MK: {
        code: 'MK',
        unicode: 'U+1F1F2 U+1F1F0',
        name: 'Macedonia',
        emoji: '🇲🇰',
        region: 'EMEA',
    },
    ML: {
        code: 'ML',
        unicode: 'U+1F1F2 U+1F1F1',
        name: 'Mali',
        emoji: '🇲🇱',
        region: 'EMEA',
    },
    MM: {
        code: 'MM',
        unicode: 'U+1F1F2 U+1F1F2',
        name: 'Myanmar (Burma)',
        emoji: '🇲🇲',
        region: 'APAC',
    },
    MN: {
        code: 'MN',
        unicode: 'U+1F1F2 U+1F1F3',
        name: 'Mongolia',
        emoji: '🇲🇳',
        region: 'APAC',
    },
    MO: {
        code: 'MO',
        unicode: 'U+1F1F2 U+1F1F4',
        name: 'Macau SAR China',
        emoji: '🇲🇴',
        region: 'APAC',
    },
    MP: {
        code: 'MP',
        unicode: 'U+1F1F2 U+1F1F5',
        name: 'Northern Mariana Islands',
        region: 'APAC',
        emoji: '🇲🇵',
    },
    MQ: {
        code: 'MQ',
        unicode: 'U+1F1F2 U+1F1F6',
        name: 'Martinique',
        emoji: '🇲🇶',
        region: 'LATM',
    },
    MR: {
        code: 'MR',
        unicode: 'U+1F1F2 U+1F1F7',
        name: 'Mauritania',
        emoji: '🇲🇷',
        region: 'EMEA',
    },
    MS: {
        code: 'MS',
        unicode: 'U+1F1F2 U+1F1F8',
        name: 'Montserrat',
        emoji: '🇲🇸',
        region: 'LATM',
    },
    MT: {
        code: 'MT',
        unicode: 'U+1F1F2 U+1F1F9',
        name: 'Malta',
        emoji: '🇲🇹',
        region: 'EMEA',
    },
    MU: {
        code: 'MU',
        unicode: 'U+1F1F2 U+1F1FA',
        name: 'Mauritius',
        emoji: '🇲🇺',
        region: 'EMEA',
    },
    MV: {
        code: 'MV',
        unicode: 'U+1F1F2 U+1F1FB',
        name: 'Maldives',
        article: true,
        emoji: '🇲🇻',
        region: 'APAC',
    },
    MW: {
        code: 'MW',
        unicode: 'U+1F1F2 U+1F1FC',
        name: 'Malawi',
        emoji: '🇲🇼',
        region: 'EMEA',
    },
    MX: {
        code: 'MX',
        unicode: 'U+1F1F2 U+1F1FD',
        name: 'Mexico',
        emoji: '🇲🇽',
        region: 'LATM',
    },
    MY: {
        code: 'MY',
        unicode: 'U+1F1F2 U+1F1FE',
        name: 'Malaysia',
        emoji: '🇲🇾',
        region: 'APAC',
    },
    MZ: {
        code: 'MZ',
        unicode: 'U+1F1F2 U+1F1FF',
        name: 'Mozambique',
        emoji: '🇲🇿',
        region: 'EMEA',
    },
    NA: {
        code: 'NA',
        unicode: 'U+1F1F3 U+1F1E6',
        name: 'Namibia',
        emoji: '🇳🇦',
        region: 'EMEA',
    },
    NC: {
        code: 'NC',
        unicode: 'U+1F1F3 U+1F1E8',
        name: 'New Caledonia',
        region: 'EMEA',
        emoji: '🇳🇨',
    },
    NE: {
        code: 'NE',
        unicode: 'U+1F1F3 U+1F1EA',
        name: 'Niger',
        emoji: '🇳🇪',
        region: 'EMEA',
    },
    NF: {
        code: 'NF',
        unicode: 'U+1F1F3 U+1F1EB',
        name: 'Norfolk Island',
        region: 'APAC',
        emoji: '🇳🇫',
    },
    NG: {
        code: 'NG',
        unicode: 'U+1F1F3 U+1F1EC',
        name: 'Nigeria',
        emoji: '🇳🇬',
        region: 'EMEA',
    },
    NI: {
        code: 'NI',
        unicode: 'U+1F1F3 U+1F1EE',
        name: 'Nicaragua',
        emoji: '🇳🇮',
        region: 'LATM',
    },
    NL: {
        code: 'NL',
        unicode: 'U+1F1F3 U+1F1F1',
        name: 'Netherlands',
        article: true,
        emoji: '🇳🇱',
        region: 'EMEA',
    },
    NO: {
        code: 'NO',
        unicode: 'U+1F1F3 U+1F1F4',
        name: 'Norway',
        emoji: '🇳🇴',
        region: 'EMEA',
    },
    NP: {
        code: 'NP',
        unicode: 'U+1F1F3 U+1F1F5',
        name: 'Nepal',
        emoji: '🇳🇵',
        region: 'APAC',
    },
    NR: {
        code: 'NR',
        unicode: 'U+1F1F3 U+1F1F7',
        name: 'Nauru',
        region: 'APAC',
        emoji: '🇳🇷',
    },
    NU: {
        code: 'NU',
        unicode: 'U+1F1F3 U+1F1FA',
        name: 'Niue',
        emoji: '🇳🇺',
        region: 'APAC',
    },
    NZ: {
        code: 'NZ',
        unicode: 'U+1F1F3 U+1F1FF',
        name: 'New Zealand',
        emoji: '🇳🇿',
        region: 'APAC',
    },
    OM: {
        code: 'OM',
        unicode: 'U+1F1F4 U+1F1F2',
        name: 'Oman',
        emoji: '🇴🇲',
        region: 'EMEA',
    },
    PA: {
        code: 'PA',
        unicode: 'U+1F1F5 U+1F1E6',
        name: 'Panama',
        emoji: '🇵🇦',
        region: 'LATM',
    },
    PE: {
        code: 'PE',
        unicode: 'U+1F1F5 U+1F1EA',
        name: 'Peru',
        emoji: '🇵🇪',
        region: 'LATM',
    },
    PF: {
        code: 'PF',
        unicode: 'U+1F1F5 U+1F1EB',
        name: 'French Polynesia',
        region: 'EMEA',
        emoji: '🇵🇫',
    },
    PG: {
        code: 'PG',
        unicode: 'U+1F1F5 U+1F1EC',
        name: 'Papua New Guinea',
        emoji: '🇵🇬',
        region: 'APAC',
    },
    PH: {
        code: 'PH',
        unicode: 'U+1F1F5 U+1F1ED',
        name: 'Philippines',
        article: true,
        emoji: '🇵🇭',
        region: 'APAC',
    },
    PK: {
        code: 'PK',
        unicode: 'U+1F1F5 U+1F1F0',
        name: 'Pakistan',
        emoji: '🇵🇰',
        region: 'APAC',
    },
    PL: {
        code: 'PL',
        unicode: 'U+1F1F5 U+1F1F1',
        name: 'Poland',
        emoji: '🇵🇱',
        region: 'EMEA',
    },
    PM: {
        code: 'PM',
        unicode: 'U+1F1F5 U+1F1F2',
        name: 'St. Pierre & Miquelon',
        region: 'EMEA',
        emoji: '🇵🇲',
    },
    PN: {
        code: 'PN',
        unicode: 'U+1F1F5 U+1F1F3',
        name: 'Pitcairn Islands',
        region: 'APAC',
        emoji: '🇵🇳',
    },
    PR: {
        code: 'PR',
        unicode: 'U+1F1F5 U+1F1F7',
        name: 'Puerto Rico',
        emoji: '🇵🇷',
        region: 'LATM',
    },
    PS: {
        code: 'PS',
        unicode: 'U+1F1F5 U+1F1F8',
        name: 'Palestinian Territories',
        region: 'EMEA',
        emoji: '🇵🇸',
    },
    PT: {
        code: 'PT',
        unicode: 'U+1F1F5 U+1F1F9',
        name: 'Portugal',
        emoji: '🇵🇹',
        region: 'EMEA',
    },
    PW: {
        code: 'PW',
        unicode: 'U+1F1F5 U+1F1FC',
        name: 'Palau',
        emoji: '🇵🇼',
        region: 'APAC',
    },
    PY: {
        code: 'PY',
        unicode: 'U+1F1F5 U+1F1FE',
        name: 'Paraguay',
        emoji: '🇵🇾',
        region: 'LATM',
    },
    QA: {
        code: 'QA',
        unicode: 'U+1F1F6 U+1F1E6',
        name: 'Qatar',
        emoji: '🇶🇦',
        region: 'EMEA',
    },
    RE: {
        code: 'RE',
        unicode: 'U+1F1F7 U+1F1EA',
        name: 'Réunion',
        region: 'EMEA',
        emoji: '🇷🇪',
    },
    RO: {
        code: 'RO',
        unicode: 'U+1F1F7 U+1F1F4',
        name: 'Romania',
        emoji: '🇷🇴',
        region: 'EMEA',
    },
    RS: {
        code: 'RS',
        unicode: 'U+1F1F7 U+1F1F8',
        name: 'Serbia',
        region: 'EMEA',
        emoji: '🇷🇸',
    },
    RU: {
        code: 'RU',
        unicode: 'U+1F1F7 U+1F1FA',
        name: 'Russia',
        emoji: '🇷🇺',
        region: 'EMEA',
    },
    RW: {
        code: 'RW',
        unicode: 'U+1F1F7 U+1F1FC',
        name: 'Rwanda',
        emoji: '🇷🇼',
        region: 'EMEA',
    },
    SA: {
        code: 'SA',
        unicode: 'U+1F1F8 U+1F1E6',
        name: 'Saudi Arabia',
        emoji: '🇸🇦',
        region: 'EMEA',
    },
    SB: {
        code: 'SB',
        unicode: 'U+1F1F8 U+1F1E7',
        name: 'Solomon Islands',
        article: true,
        emoji: '🇸🇧',
        region: 'APAC',
    },
    SC: {
        code: 'SC',
        unicode: 'U+1F1F8 U+1F1E8',
        name: 'Seychelles',
        emoji: '🇸🇨',
        region: 'EMEA',
    },
    SD: {
        code: 'SD',
        unicode: 'U+1F1F8 U+1F1E9',
        name: 'Sudan',
        emoji: '🇸🇩',
        region: 'EMEA',
    },
    SE: {
        code: 'SE',
        unicode: 'U+1F1F8 U+1F1EA',
        name: 'Sweden',
        emoji: '🇸🇪',
        region: 'EMEA',
    },
    SG: {
        code: 'SG',
        unicode: 'U+1F1F8 U+1F1EC',
        name: 'Singapore',
        emoji: '🇸🇬',
        region: 'APAC',
    },
    SH: {
        code: 'SH',
        unicode: 'U+1F1F8 U+1F1ED',
        name: 'St. Helena',
        region: 'EMEA',
        emoji: '🇸🇭',
    },
    SI: {
        code: 'SI',
        unicode: 'U+1F1F8 U+1F1EE',
        name: 'Slovenia',
        emoji: '🇸🇮',
        region: 'EMEA',
    },
    SJ: {
        code: 'SJ',
        unicode: 'U+1F1F8 U+1F1EF',
        name: 'Svalbard & Jan Mayen',
        region: 'EMEA',
        emoji: '🇸🇯',
    },
    SK: {
        code: 'SK',
        unicode: 'U+1F1F8 U+1F1F0',
        name: 'Slovakia',
        emoji: '🇸🇰',
        region: 'EMEA',
    },
    SL: {
        code: 'SL',
        unicode: 'U+1F1F8 U+1F1F1',
        name: 'Sierra Leone',
        emoji: '🇸🇱',
        region: 'EMEA',
    },
    SM: {
        code: 'SM',
        unicode: 'U+1F1F8 U+1F1F2',
        name: 'San Marino',
        emoji: '🇸🇲',
        region: 'EMEA',
    },
    SN: {
        code: 'SN',
        unicode: 'U+1F1F8 U+1F1F3',
        name: 'Senegal',
        emoji: '🇸🇳',
        region: 'EMEA',
    },
    SO: {
        code: 'SO',
        unicode: 'U+1F1F8 U+1F1F4',
        name: 'Somalia',
        emoji: '🇸🇴',
        region: 'EMEA',
    },
    SR: {
        code: 'SR',
        unicode: 'U+1F1F8 U+1F1F7',
        name: 'Suriname',
        emoji: '🇸🇷',
        region: 'LATM',
    },
    SS: {
        code: 'SS',
        unicode: 'U+1F1F8 U+1F1F8',
        name: 'South Sudan',
        region: 'EMEA',
        emoji: '🇸🇸',
    },
    ST: {
        code: 'ST',
        unicode: 'U+1F1F8 U+1F1F9',
        name: 'São Tomé & Príncipe',
        emoji: '🇸🇹',
        region: 'EMEA',
    },
    SV: {
        code: 'SV',
        unicode: 'U+1F1F8 U+1F1FB',
        name: 'El Salvador',
        emoji: '🇸🇻',
        region: 'LATM',
    },
    SX: {
        code: 'SX',
        unicode: 'U+1F1F8 U+1F1FD',
        name: 'Sint Maarten',
        emoji: '🇸🇽',
        region: 'LATM',
    },
    SY: {
        code: 'SY',
        unicode: 'U+1F1F8 U+1F1FE',
        name: 'Syria',
        emoji: '🇸🇾',
        region: 'EMEA',
    },
    SZ: {
        code: 'SZ',
        unicode: 'U+1F1F8 U+1F1FF',
        name: 'Swaziland',
        emoji: '🇸🇿',
        region: 'EMEA',
    },
    TA: {
        code: 'TA',
        unicode: 'U+1F1F9 U+1F1E6',
        name: 'Tristan da Cunha',
        region: 'EMEA',
        emoji: '🇹🇦',
    },
    TC: {
        code: 'TC',
        unicode: 'U+1F1F9 U+1F1E8',
        name: 'Turks & Caicos Islands',
        region: 'LATM',
        article: true,
        emoji: '🇹🇨',
    },
    TD: {
        code: 'TD',
        unicode: 'U+1F1F9 U+1F1E9',
        name: 'Chad',
        emoji: '🇹🇩',
        region: 'EMEA',
    },
    TF: {
        code: 'TF',
        unicode: 'U+1F1F9 U+1F1EB',
        name: 'French Southern Territories',
        region: 'EMEA',
        emoji: '🇹🇫',
    },
    TG: {
        code: 'TG',
        unicode: 'U+1F1F9 U+1F1EC',
        name: 'Togo',
        emoji: '🇹🇬',
        region: 'EMEA',
    },
    TH: {
        code: 'TH',
        unicode: 'U+1F1F9 U+1F1ED',
        name: 'Thailand',
        emoji: '🇹🇭',
        region: 'APAC',
    },
    TJ: {
        code: 'TJ',
        unicode: 'U+1F1F9 U+1F1EF',
        name: 'Tajikistan',
        emoji: '🇹🇯',
        region: 'APAC',
    },
    TK: {
        code: 'TK',
        unicode: 'U+1F1F9 U+1F1F0',
        name: 'Tokelau',
        region: 'APAC',
        emoji: '🇹🇰',
    },
    TL: {
        code: 'TL',
        unicode: 'U+1F1F9 U+1F1F1',
        name: 'Timor-Leste',
        region: 'APAC',
        emoji: '🇹🇱',
    },
    TM: {
        code: 'TM',
        unicode: 'U+1F1F9 U+1F1F2',
        name: 'Turkmenistan',
        emoji: '🇹🇲',
        region: 'APAC',
    },
    TN: {
        code: 'TN',
        unicode: 'U+1F1F9 U+1F1F3',
        name: 'Tunisia',
        emoji: '🇹🇳',
        region: 'EMEA',
    },
    TO: {
        code: 'TO',
        unicode: 'U+1F1F9 U+1F1F4',
        name: 'Tonga',
        emoji: '🇹🇴',
        region: 'APAC',
    },
    TR: {
        code: 'TR',
        unicode: 'U+1F1F9 U+1F1F7',
        name: 'Turkey',
        emoji: '🇹🇷',
        region: 'APAC',
    },
    TT: {
        code: 'TT',
        unicode: 'U+1F1F9 U+1F1F9',
        name: 'Trinidad & Tobago',
        emoji: '🇹🇹',
        region: 'LATM',
    },
    TV: {
        code: 'TV',
        unicode: 'U+1F1F9 U+1F1FB',
        name: 'Tuvalu',
        emoji: '🇹🇻',
        region: 'APAC',
    },
    TW: {
        code: 'TW',
        unicode: 'U+1F1F9 U+1F1FC',
        name: 'Taiwan',
        emoji: '🇹🇼',
        region: 'APAC',
    },
    TZ: {
        code: 'TZ',
        unicode: 'U+1F1F9 U+1F1FF',
        name: 'Tanzania',
        emoji: '🇹🇿',
        region: 'EMEA',
    },
    UA: {
        code: 'UA',
        unicode: 'U+1F1FA U+1F1E6',
        name: 'Ukraine',
        emoji: '🇺🇦',
        region: 'EMEA',
    },
    UG: {
        code: 'UG',
        unicode: 'U+1F1FA U+1F1EC',
        name: 'Uganda',
        emoji: '🇺🇬',
        region: 'EMEA',
    },
    UM: {
        code: 'UM',
        unicode: 'U+1F1FA U+1F1F2',
        name: 'U.S. Outlying Islands',
        region: 'APAC',
        emoji: '🇺🇲',
    },
    US: {
        code: 'US',
        unicode: 'U+1F1FA U+1F1F8',
        name: 'United States',
        article: true,
        emoji: '🇺🇸',
        region: 'NorthAmerica',
    },
    UY: {
        code: 'UY',
        unicode: 'U+1F1FA U+1F1FE',
        name: 'Uruguay',
        emoji: '🇺🇾',
        region: 'LATM',
    },
    UZ: {
        code: 'UZ',
        unicode: 'U+1F1FA U+1F1FF',
        name: 'Uzbekistan',
        emoji: '🇺🇿',
        region: 'APAC',
    },
    VA: {
        code: 'VA',
        unicode: 'U+1F1FB U+1F1E6',
        name: 'Vatican City',
        emoji: '🇻🇦',
        region: 'EMEA',
    },
    VC: {
        code: 'VC',
        unicode: 'U+1F1FB U+1F1E8',
        name: 'St. Vincent & Grenadines',
        region: 'LATM',
        emoji: '🇻🇨',
    },
    VE: {
        code: 'VE',
        unicode: 'U+1F1FB U+1F1EA',
        name: 'Venezuela',
        emoji: '🇻🇪',
        region: 'LATM',
    },
    VG: {
        code: 'VG',
        unicode: 'U+1F1FB U+1F1EC',
        name: 'British Virgin Islands',
        article: true,
        emoji: '🇻🇬',
        region: 'LATM',
    },
    VI: {
        code: 'VI',
        unicode: 'U+1F1FB U+1F1EE',
        name: 'U.S. Virgin Islands',
        article: true,
        emoji: '🇻🇮',
        region: 'LATM',
    },
    VN: {
        code: 'VN',
        unicode: 'U+1F1FB U+1F1F3',
        name: 'Vietnam',
        emoji: '🇻🇳',
        region: 'APAC',
    },
    VU: {
        code: 'VU',
        unicode: 'U+1F1FB U+1F1FA',
        name: 'Vanuatu',
        emoji: '🇻🇺',
        region: 'APAC',
    },
    WF: {
        code: 'WF',
        unicode: 'U+1F1FC U+1F1EB',
        name: 'Wallis & Futuna',
        region: 'EMEA',
        emoji: '🇼🇫',
    },
    WS: {
        code: 'WS',
        unicode: 'U+1F1FC U+1F1F8',
        name: 'Samoa',
        emoji: '🇼🇸',
        region: 'APAC',
    },
    XK: {
        code: 'XK',
        unicode: 'U+1F1FD U+1F1F0',
        name: 'Kosovo',
        region: 'EMEA',
        emoji: '🇽🇰',
    },
    YE: {
        code: 'YE',
        unicode: 'U+1F1FE U+1F1EA',
        name: 'Yemen',
        emoji: '🇾🇪',
        region: 'EMEA',
    },
    YT: {
        code: 'YT',
        unicode: 'U+1F1FE U+1F1F9',
        name: 'Mayotte',
        region: 'EMEA',
        emoji: '🇾🇹',
    },
    ZA: {
        code: 'ZA',
        unicode: 'U+1F1FF U+1F1E6',
        name: 'South Africa',
        emoji: '🇿🇦',
        region: 'EMEA',
    },
    ZM: {
        code: 'ZM',
        unicode: 'U+1F1FF U+1F1F2',
        name: 'Zambia',
        emoji: '🇿🇲',
        region: 'EMEA',
    },
    ZW: {
        code: 'ZW',
        unicode: 'U+1F1FF U+1F1FC',
        name: 'Zimbabwe',
        emoji: '🇿🇼',
        region: 'EMEA',
    },
};
