import axios from 'axios';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
const URL = process.env.CHARTS_API_URL;
const defaultSwrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: true,
    dedupingInterval: 3_600_000,
};
export const getDownloader = () => {
    return ([url, query, variables, template, headers]) => axios
        .post(url, {
        query,
        variables,
        template,
    }, {
        responseType: 'blob',
        headers: {
            'Content-Type': variables ? 'application/json' : 'text/plain',
            ...headers,
        },
    })
        .then((response) => {
        const [, filename] = response.headers['content-disposition'].split('=');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
    });
};
export const getFetcher = () => {
    return ([url, query, variables]) => axios.post(url, variables
        ? {
            query,
            variables,
        }
        : query, {
        headers: {
            'Content-Type': variables ? 'application/json' : 'text/plain',
        },
    });
};
export const useV2Slicer = (query, source, options) => {
    return useSWR(() => (query ? [`${URL}/api/v2?source=${source}`, query] : null), getFetcher(), options);
};
export const useChartsSlicer = (query, source, options) => {
    let { isLoading, error, data } = useSWR(() => (query ? [`/api/charts/?source=${source}`, query] : null), getFetcher(), { ...defaultSwrOptions, ...options });
    if (data?.data?.error && data?.data?.message) {
        error = data.data;
        data = { data: null };
    }
    if (error)
        console.error(error);
    return {
        isLoading,
        error,
        raw: data?.data,
    };
};
export const useChartsSlicerV3 = (query, source, provider, variables, options) => {
    return useSWR(() => query ? [`/api/v3?source=${source}&provider=${provider || 'pg'}`, query, variables] : null, getFetcher(), { ...defaultSwrOptions, ...options });
};
export const useSlicer = (query, source, provider, variables, options) => {
    return useSWR(() => query ? [`/api/v3?source=${source}&provider=${provider || 'pg'}`, query, variables] : null, getFetcher(), options);
};
export const useSlicerInfinite = (query, source, keyUpdater, provider, variables, options) => {
    return useSWRInfinite((index, previousData) => query
        ? keyUpdater([`${URL}/api/v3?source=${source}&provider=${provider || 'pg'}`, query, variables], index, previousData)
        : null, getFetcher(), options);
};
export function transformToUnique(arr) {
    return Array.from(new Set(arr));
}
export const defaultTransformer = (value) => (Array.isArray(value) ? transformToUnique(value).join('|') : value) || null;
const defaultFilters = {
    dateTo: ['lte', defaultTransformer],
    dateFrom: ['gte', defaultTransformer],
};
export const addDefaultFilter = (key, operator, valueTransformer) => {
    defaultFilters[key] = [operator, valueTransformer];
};
export const TypedFilterKey = [
    'in',
    'like',
    'ilike',
    'gt',
    'gte',
    'lt',
    'lte',
    'eq',
    'neq',
];
export function detectDefaultTypedFilter(field, filter) {
    if (Array.isArray(filter) && (filter.length === 2 || filter.length === 3)) {
        if (filter.length === 2) {
            const key = filter[1];
            if (typeof key === 'function') {
                filter.push(key);
                filter[1] = field;
            }
        }
        if (typeof filter[1] === 'string') {
            if (TypedFilterKey.findIndex((el) => el === filter[1]) === -1) {
                throw new Error(`Filter key must be one of ${JSON.stringify(TypedFilterKey)}`);
            }
        }
        return [filter[0], filter[1], filter[2] || defaultFilters[field] || defaultTransformer];
    }
    else {
        return [
            filter || null,
            ...(defaultFilters[field] || [field, defaultTransformer]),
        ];
    }
}
export const getDefaultFilters = (filters) => {
    return Object.keys(filters)
        .map((key) => [key, detectDefaultTypedFilter(key, filters[key])])
        .reduce((filter, [field, el]) => {
        const [data, key, transform] = el;
        const transformed = transform(data);
        if (transformed !== undefined) {
            filter[field] = filter[field] || {};
            filter[field][key] = transformed;
        }
        return filter;
    }, {});
};
